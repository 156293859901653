import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import withPageStructure from "../hoc/withPageStructure";
import Section from "../components/Section/Section";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
      loginParams: {
        user_password: "",
      },
    };
  }

  handleFormChange = (event) => {
    let loginParamsNew = { ...this.state.loginParams };
    let val = event.target.value;
    loginParamsNew[event.target.name] = val;
    this.setState({
      loginParams: loginParamsNew,
    });
  };

  login = (event) => {
    let user_password = this.state.loginParams.user_password;

    fetch(
      "https://alexander.johmann.eu/api/user/login.php?username=" +
        user_password +
        "&password=" +
        user_password
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          localStorage.setItem("token", user_password);
          this.setState({
            islogged: true,
          });
        }
      })
      .catch(console.log);
    event.preventDefault();
  };

  render() {
    if (localStorage.getItem("token")) {
      let redirect = this.props.history.location.state
        ? this.props.history.location.state.from.pathname
        : "/";
      return <Navigate to={redirect} />;
    }
    return (
      <Section classes="center">
        <section>
          <h2>
            <span>Please enter the password to proceed</span>
          </h2>
          <form onSubmit={this.login} className="form-signin">
            <input
              type="password"
              name="user_password"
              onChange={this.handleFormChange}
              placeholder="Enter Password"
            />
            <input type="submit" value="Login" />
          </form>
        </section>
      </Section>
    );
  }
}

export default withPageStructure(Login);
