import React from "react";
import styles from "./Hero.module.scss";

const Hero = (props) => {
  return (
    <div className={styles.hero}>
      <div
        className={styles.image}
        style={{
          backgroundImage: "url(images/anton-DcojOveFhT8-unsplash.jpg",
        }}
      >
        <span>
          Photo by{" "}
          <a href="https://unsplash.com/@uniqueton?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Anton
          </a>{" "}
          on{" "}
          <a href="https://unsplash.com/s/photos/vienna?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </span>
      </div>
      <div className={styles.content}>
        <p>
          I’m Alex, a{" "}
          <span className="marked">
            Digital Consultant and User Experience Designer
          </span>
          * based in Vienna, Austria
        </p>
        <p>
          *) <a href="/info">Learn more</a>
        </p>
      </div>
    </div>
  );
};

export default Hero;
