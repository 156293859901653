import React from "react";
import styles from "./ProjectCard.module.scss";
import PropTypes from "prop-types";
import { Fade } from "react-awesome-reveal";

const ProjectCard = (props) => {
  const classes = [styles.card];
  if (props.size === "large") classes.push(styles.large);
  else if (props.size === "small") classes.push(styles.small);
  if (props.theme === "alternate") classes.push(styles.light);

  return (
    <Fade>
      <div className={classes.join(" ")} onClick={props.clicked}>
        <div
          className={styles.image}
          style={{ backgroundImage: "url(/images/" + props.image + ")" }}
        ></div>
        <div
          className={styles.content}
          style={{ backgroundColor: props.color }}
        ></div>
        <h4 style={{ textShadow: "2px 2px 0 " + props.color }}>
          <div>{props.client}</div>
          {props.project}
        </h4>
        <button>Learn more…</button>
      </div>
    </Fade>
  );
};

ProjectCard.propTypes = {
  clicked: PropTypes.func,
  image: PropTypes.string,
  color: PropTypes.string,
  client: PropTypes.string,
  project: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string,
  period: PropTypes.string,
  agency: PropTypes.string,
  technology: PropTypes.string,
};

export default ProjectCard;
