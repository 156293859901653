import React from 'react';
import './Footer.module.scss';

const Footer = props => (
    <footer className={ props.footerStyle } style={{ backgroundColor: props.footerBackground }}>
        <h2>Get in touch</h2>
        <ul>
            <li><a href='mailto:alexander.johmann@me.com'><img alt='Email Icon' src='/images/email.svg' /></a></li>
            <li><a href='https://www.linkedin.com/in/alexanderjohmann/'><img alt='LinkedIn Icon' src='/images/linkedin.svg' /></a></li>
            <li><a href='https://twitter.com/ajohmann'><img alt='Twitter Icon' src='/images/twitter.svg' /></a></li>
            <li><a href='https://github.com/alexander-johmann'><img alt='GitHub Icon' src='/images/github.svg' /></a></li>
        </ul>
    </footer>
);

export default Footer;