import React from 'react';
import styles from './ClientGrid.module.scss';
import ClientCard from '../ClientCard/ClientCard';

const ClientGrid = props => {
    
    return (
        <section className={ styles.clients }>
           { props.clients.map( (project, index) => {
                return <ClientCard key={ index } image={ '/images/brands/' + project.image } client={ project.client } color={ project.color } theme={ project.theme } />;
            } ) }
        </section>
    );

}

export default ClientGrid;