import React from "react";
import "./Header.module.scss";
import { Link, NavLink } from "react-router-dom";
import Title from "../Title/Title";

const Header = (props) => {
  return (
    <header
      className={props.headerStyle}
      style={{ backgroundColor: props.headerBackground }}
    >
      <h2>
        <Link to="/">Alexander Johmann</Link>
      </h2>
      <nav>
        <ul>
          <li>
            <NavLink to="/work">Work</NavLink>
          </li>
          <li>
            <NavLink to="/play">Play</NavLink>
          </li>
          <li>
            <NavLink to="/info">Info</NavLink>
          </li>
        </ul>
      </nav>
      <Title />
    </header>
  );
};

export default Header;
