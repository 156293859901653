import React from "react";
import "./Title.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Title = (props) => {
  let title = "";
  let location = useLocation();
  if (location.pathname === "/work") title = "Selected projects";
  else if (location.pathname === "/play") title = "Side projects";
  else if (location.pathname === "/info") title = "About me";
  else if (location.pathname === "/login") title = "Login";

  return <h1>{title}</h1>;
};

export default Title;
