import React from "react";
import Section from "../components/Section/Section";
import styles from "./Info.module.scss";
import withPageStructure from "../hoc/withPageStructure";
import ClientGrid from "../components/ClientGrid/ClientGrid";
import * as clientData from "../contents/clients.json";
import Image from "../components/Image/Image";
import { Fade } from "react-awesome-reveal";

const Info = () => {
  let data = clientData;
  const ecxioClients = [...data.ecxio];
  const whateverworksClients = [...data.whateverworks];
  const virtualidentityClients = [...data.virtualidentity];

  return (
    <Section classes={["center", styles.info]}>
      <section className="grid67-33 centered">
        <cite>
          <span className="marked">
            I help teams to design and build innovative and meaningful digital
            solutions, by connecting technology, business and design. I hold a
            diploma in Informatics and have 15+ years of experience as Designer,
            Consultant, Project Manager and Entrepreneur.
          </span>
        </cite>
        <Image image="/images/alexander-johmann.jpg" border="true" />
      </section>

      <section>
        <h2>
          <span>Experience</span>
        </h2>

        <section className="block">
          <h3>
            <div>Since June 2021</div>
            Head of Business Consulting
            <div>
              <a href="https://www.ibmix.de">IBM iX</a>, Vienna/Austria
            </div>
          </h3>
          <ul className="listing">
            <li>
              Leading the Business Consulting Digital Growth Team with focus on
              Research & Insights, Web-Analytics, Search Engine Marketing,
              Personalisation & Conversion Rate Optimisation, and Digital
              Marketing Strategy
            </li>
            <li>
              Consulting and guiding clients and agile project teams on the
              topics experience design and growth
            </li>
            <li>
              <a href="https://www.ibm.com/design/thinking/">
                Enterprise Design Thinking
              </a>{" "}
              chapter lead, IBM iX Studio Vienna
            </li>
          </ul>
        </section>

        <section className="block">
          <h3>
            <div>May 2016 – May 2021</div>
            Senior User Experience Designer
            <div>
              <a href="https://www.ibmix.de">IBM iX</a> (formerly ecx.io),
              Vienna/Austria
            </div>
          </h3>
          <ul className="listing">
            <li>
              Designing and enhancing user experiences for e-commerce (b2b and
              b2c), corporate and business websites, portals/applications
            </li>
            <li>
              Consulting and guiding clients and agile project teams on the
              topic experience design
            </li>
            <li>
              Leading design thinking adaption as co-lead for the{" "}
              <a href="https://www.ibm.com/design/thinking/">
                Enterprise Design Thinking
              </a>{" "}
              chapter, IBM iX Studio Vienna
            </li>
          </ul>

          <Fade>
            <ClientGrid clients={ecxioClients} />
          </Fade>
        </section>

        <section className="block">
          <h3>
            <div>July 2013 – September 2017</div>
            Tutor "Concept &amp; User Experience"
            <div>
              <a href="https://www.sae.edu/aut/">SAE Institute</a>,
              Vienna/Austria
            </div>
          </h3>
        </section>

        <section className="block">
          <h3>
            <div>September 2011 – March 2016</div>
            Co-Founder &amp; Managing Director
            <div>
              <a href="http://whateverworks.at">whateverworks</a>,
              Vienna/Austria
            </div>
          </h3>
          <ul className="listing">
            <li>Managing clients and partners, acquisition of clients</li>
            <li>
              Consulting (digital communication, user experience, online/social
              media marketing, analytics)
            </li>
            <li>
              Ideating and designing digital brand communication solutions (web,
              social media, intranet)
            </li>
            <li>Account/project management</li>
          </ul>

          <Fade>
            <ClientGrid clients={whateverworksClients} />
          </Fade>
        </section>

        <section className="block">
          <h3>
            <div>September 2007 - August 2011</div>
            Consultant &amp; Projekt Manager
            <div>
              <a href="https://www.virtual-identity.com">Virtual Identity</a>,
              Freiburg/Germany &amp; Vienna/Austria
            </div>
          </h3>
          <ul className="listing">
            <li>
              Consulting (digital communication, user experience, online/social
              media marketing, analytics)
            </li>
            <li>
              Ideating and designing digital brand communication solutions
              (corporate and business websites, digital brand management
              platforms, social media campaigns, etc.)
            </li>
            <li>Account/project management</li>
          </ul>

          <Fade>
            <ClientGrid clients={virtualidentityClients} />
          </Fade>
        </section>
      </section>

      <section>
        <h2>
          <span>Education</span>
        </h2>

        <section className="block">
          <h3>
            <div>2003 – 2007 (8 semesters)</div>
            Dipl. Inform. (FH) – Digital and Online Media
            <div>
              <a href="https://www.hs-furtwangen.de">
                Hochschule Furtwangen University
              </a>
              , Germany
            </div>
          </h3>
          <p>
            2007: Thesis about web navigation with{" "}
            <a href="https://www.virtual-identity.com">Virtual Identity</a>,
            Graduation (final grade: 1,8)
          </p>
          <p>
            2006-2007: Internship at{" "}
            <a href="https://www.virtual-identity.com">Virtual Identity</a>{" "}
            (consulting &amp; project management, 6 month)
          </p>
          <p>2004-2005: Tutor for XHTML &amp; CSS, two semesters</p>
          <p>
            2004: Internship at <a href="https://www.mit.de">M.I.T newmedia</a>{" "}
            (frontend development, 6 month)
          </p>
        </section>
      </section>

      <section>
        <h2>
          <span>Skills</span>
        </h2>
        <ul className="listing columns">
          <li>User Experience Design</li>
          <li>Information Architecture</li>
          <li>Concept &amp; Ideation</li>
          <li>Creative Direction & Design Management</li>
          <li>Design Thinking &amp; Design Sprints</li>
          <li>Design Ops &amp; Design Systems</li>
          <li>User Research &amp; Testing</li>
          <li>Usability Engineering</li>
          <li>Prototyping</li>
          <li>Frontend Development (HTML, CSS, Javascript)</li>
          <li>React Development</li>
          <li>Swift Development & Apple OS Frameworks</li>
          <li>Project Management</li>
          <li>Scrum, Kanban</li>
          <li>Requirements Engineering</li>
          <li>Analytics &amp; Growth Hacking</li>
          <li>Online &amp; Social Media Marketing</li>
          <li>Moderation</li>
          <li>Teaching</li>
          <li>German &amp; English</li>
        </ul>
      </section>
    </Section>
  );
};

export default withPageStructure(Info);
