import React from "react";
import withPageStructure from "../hoc/withPageStructure";
import Hero from "../components/Hero/Hero";
// import styles from "./Home.module.scss";

const Home = () => {
  return <Hero />;
};

export default withPageStructure(Home, "alternate", false);
