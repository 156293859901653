import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WithClass from "../hoc/WithClass";
import styles from "./App.module.scss";
import Home from "./Home";
import Work from "./Work";
import Play from "./Play";
import Info from "./Info";
import ScrollToTop from "../hoc/ScrollToTop";
import { ParallaxProvider } from "react-scroll-parallax";
import Login from "./Login";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <ParallaxProvider>
            <WithClass classes={styles.app}>
              <Routes>
                <Route path="/*" element={<Home />} />
                <Route path="/login/*" element={<Login />} />
                <Route path="/work/*" element={<Work />} />
                <Route path="/play/*" element={<Play />} />
                <Route path="/info/*" element={<Info />} />
              </Routes>
            </WithClass>
          </ParallaxProvider>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
