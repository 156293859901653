import React from 'react';
import styles from './ClientCard.module.scss';

const ClientCard = props => {

    return (
        <div className={ styles.card }>
            <div className={ styles.brand }>
                <img src={ props.image } alt={ 'Logo von ' + props.client } />
            </div>
            <div className={ styles.client } style={{ backgroundColor: props.color }}>
                <caption className={ [props.theme, 'center'].join(' ') }>{ props.client }</caption>
            </div>
        </div>
    );

}

export default ClientCard;