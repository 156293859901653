import React, { Component } from "react";
import ProjectGrid from "../components/ProjectGrid/ProjectGrid";
import * as projectData from "../contents/projects.json";
import Section from "../components/Section/Section";
import withPageStructure from "../hoc/withPageStructure";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const Work = () => {
  return (
    <Section classes="center">
      <section>
        <h2>
          <span>2016–today with IBM iX</span>
        </h2>
        <ul className="listing">
          <li>
            <strong>Fiskars OneDigital</strong> (2018-2022)
            <br />
            Concept and User Experience for the ecommerce platform of Fiskars
            and its several subbrands.
          </li>
          <li>
            <strong>Raiffeisen.at Relaunch</strong> (2017-2018)
            <br />
            User Experience Consulting for the Website of the Raiffeisen Bank in
            Austria.
          </li>
          <li>
            <strong>Wienerberger Building Solutions</strong> (2018)
            <br />
            User Experience for the websites of Wienerberger Building Solutions.
          </li>
          <li>
            <strong>Walter Group Careers</strong> (2017)
            <br />
            Concept for the careers portal of Walter Group.
          </li>
        </ul>
      </section>
      <section>
        <h2>
          <span>2011–2016 with whateverworks</span>
        </h2>
        <ul className="listing">
          <li>
            <strong>voestalpine Corporate Search</strong> (2015)
            <br />
            Concept and project management for the implementation of a unified
            search with various sources (corporate and company websites,
            products, locations, jobs, blog postings).
          </li>
          <li>
            <strong>voestalpine Web Analytics</strong> (2014-2015)
            <br />
            Planning and setup of a company-wide web analytics together with
            reporting dashboards for various departments.
          </li>
          <li>
            <strong>voestalpine Product Search</strong> (2012)
            <br />
            Concept and project management for the implementation of a product
            search accross all companies of the group.
          </li>
        </ul>
      </section>
      <section>
        <h2>
          <span>2006–2011 with Virtual Identity</span>
        </h2>
        <ul className="listing">
          <li>
            <strong>voestalpine Corporate Website</strong> (2009-2010)
            <br />
            Concept and project managment for the corporate website of
            voestalpine.
          </li>
          <li>
            <strong>Sunways Corporate Website</strong> (2009)
            <br />
            Concept and project managment for the corporate website of Sunways
            (company dissolved).
          </li>
          <li>
            <strong>Siemens webweb*</strong> (2008)
            <br />
            Concept and project managment for the creation of the web brand
            management platform webweb* of Siemens.
          </li>
          <li>
            <strong>Evonik Brand Websites</strong> (2008)
            <br />
            Concept for the brand websites of Evonik.
          </li>
          <li>
            <strong>Testo Product Landingpages</strong> (2007-2009)
            <br />
            Concept and project management for various landingpages for new
            products of testo.
          </li>
        </ul>
      </section>
    </Section>
  );
};

class WorkOLD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogin: false,
      islogout: false,
    };
    this.checkLogin();
  }

  signOut = () => {
    localStorage.removeItem("token");
    this.setState({
      islogout: true,
    });
  };

  checkLogin = () => {
    let user_password = localStorage.getItem("token");
    if (user_password) {
      fetch(
        "https://alexander.johmann.eu/api/user/login.php?username=" +
          user_password +
          "&password=" +
          user_password
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.setState({
              islogin: true,
            });
          } else {
            this.signOut();
          }
        })
        .catch(console.log);
    }
  };

  render() {
    if (this.state.islogout) {
      return <Navigate to="/login" />;
    }
    if (!this.state.islogin) {
      return "";
    }

    // const { match } = this.props;

    let data = projectData;
    let ecx = [...data.featured, ...data.ecxio];
    // let wwx = [...projectData.whateverworks];
    // let vid = [...projectData.virtualidentity];

    return (
      <Section classes="center">
        <section>
          <h2>
            <span>2016–today with IBM iX (formerly ecx.io)</span>
          </h2>
          <ProjectGrid projects={ecx} />
        </section>
        <section>
          <h2>
            <span>2011–2016 with whateverworks</span>
          </h2>
          <ul className="listing">
            <li>
              <strong>voestalpine Corporate Search</strong> (2015)
              <br />
              Concept and project management for the implementation of a unified
              search with various sources (corporate and company websites,
              products, locations, jobs, blog postings).
            </li>
            <li>
              <strong>voestalpine Web Analytics</strong> (2014/5)
              <br />
              Planning and setup of a company-wide web analytics together with
              reporting dashboards for various departments.
            </li>
            <li>
              <strong>voestalpine Product Search</strong> (2012)
              <br />
              Concept and project management for the implementation of a product
              search accross all companies of the group.
            </li>
          </ul>
        </section>
        <section>
          <h2>
            <span>2006–2011 with Virtual Identity</span>
          </h2>
          <ul className="listing">
            <li>
              <strong>voestalpine Corporate Website</strong> (2009/10)
              <br />
              Concept and project managment for the corporate website of
              voestalpine.
            </li>
            <li>
              <strong>Sunways Corporate Website</strong> (2009)
              <br />
              Concept and project managment for the corporate website of Sunways
              (company dissolved).
            </li>
            <li>
              <strong>Siemens webweb*</strong> (2008)
              <br />
              Concept and project managment for the creation of the web brand
              management platform webweb* of Siemens.
            </li>
            <li>
              <strong>Evonik Brand Websites</strong> (2008)
              <br />
              Concept for the brand websites of Evonik.
            </li>
            <li>
              <strong>Testo Product Landingpages</strong> (2007-9)
              <br />
              Concept and project management for various landingpages for new
              products of testo.
            </li>
          </ul>
        </section>
      </Section>
    );
  }
}

export default withPageStructure(withRouter(Work));
