import React from "react";
import { Fade } from "react-awesome-reveal";
import styles from "./Image.module.scss";

const Image = (props) => {
  let style = null;
  if (props.border === "true") style = styles.border;

  return (
    <Fade>
      <figure>
        <img src={props.image} alt={props.alttext} className={style} />
      </figure>
    </Fade>
  );
};

export default Image;
