import React, { Fragment } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const withPageStructure = (Component, headerStyle, footer=true) => {
    return props => (
        <Fragment>
            <Header headerStyle={ headerStyle } />
            <Component {...props} />
            {(() => {
                if (footer) {
                    return <Footer />
                }
            })()}
        </Fragment>
    );
}

export default withPageStructure;