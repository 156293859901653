import React, { useEffect } from "react";
import styles from "./Play.module.scss";
import Section from "../components/Section/Section";
import withPageStructure from "../hoc/withPageStructure";
import { Fade } from "react-awesome-reveal";

const Play = () => {
  useEffect(() => {
    const background = document.body.style.background;
    document.body.style.background = "#202020";
    return () => {
      document.body.style.background = background;
    };
  }, []);

  return (
    <Section classes={["play", "full", styles.play].join(" ")}>
      <section
        className={[styles.inverted, styles.project, styles.couchreader].join(
          " "
        )}
      >
        <Fade>
          <article className={styles.inverted}>
            <span className={styles.badge}>In active development</span>
            <figure>
              <img
                src="images/play/couchreader.svg"
                alt="Couch Reader App Icon"
              />
            </figure>
            <div>
              <h3>Couch Reader for Pocket</h3>
              <p>
                A beautiful and easy-to-use alternative for the official Pocket
                client on iPhone, iPad and Mac. Native app built with Swift.
              </p>
              <a href="https://couchreader.app">Visit Project</a>
            </div>
          </article>
        </Fade>
      </section>

      <section className={[styles.project, styles.sketchessentials].join(" ")}>
        <Fade>
          <article>
            <span className={styles.badge}>Updated Apr'20</span>
            <figure>
              <img src="images/play/sketch.svg" alt="Sketch Icon" />
            </figure>
            <div>
              <h3>Sketch Essentials</h3>
              <p>
                My collection of hand-picked extensions and learning material
                for the leading digital design software Sketch. Designed in
                Sketch, built with React.
              </p>
              <a href="https://sketch.essentials.design">Visit Project</a>
            </div>
          </article>
        </Fade>
      </section>

      <section
        className={[styles.inverted, styles.project, styles.top100brands].join(
          " "
        )}
      >
        <Fade>
          <article className={styles.inverted}>
            <span className={styles.badge}>Updated Oct'18</span>
            <figure></figure>
            <div>
              <h3>Top 100 Brands</h3>
              <p>
                A side project built in 2014 to visualize the origin of the
                world's most valuable brands according to MillwardBrown’s BrandZ
                study on a map.
              </p>
              <a href="http://top100brands.johmann.eu">Visit Project</a>
            </div>
          </article>
        </Fade>
      </section>

      <section className={[styles.project, styles.water].join(" ")}>
        <Fade>
          <article>
            <figure>
              <img src="images/play/water.svg" alt="Water App Icon" />
            </figure>
            <div>
              <h3>Water: Liquid Tracker</h3>
              <p>
                The most beautiful (at it's time) personal liquid tracker and
                reminder for the iPhone. Native iOS app built between 2014 and
                2016 in Swift. Not anymore in active development, but still used
                by 100+ users.
              </p>
              <a href="http://apps.johmann.eu/water/">Visit Project</a>
            </div>
          </article>
        </Fade>
      </section>
    </Section>
  );
};

export default withPageStructure(Play);
