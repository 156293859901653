import React from "react";
import ProjectCard from "../ProjectCard/ProjectCard";
import styles from "./ProjectGrid.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const ProjectGrid = (props) => {
  const cardClickedHandler = (project) => {
    props.history.push("/work/" + project);
  };

  let cardSize = "small";
  if (props.type === "featured") cardSize = "large";

  return (
    <section className={styles.projectgrid + " num" + props.projects.length}>
      {props.projects.map((project, index) => {
        return (
          <ProjectCard
            key={index}
            client={project.client}
            project={project.project}
            image={project.image}
            color={project.color}
            theme={project.theme}
            size={cardSize}
            clicked={() => cardClickedHandler(project.id)}
          />
        );
      })}
    </section>
  );
};

export default withRouter(ProjectGrid);
