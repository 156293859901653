import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const rootElement = document.getElementById("root");
  if (rootElement) {
    rootElement.style.animation = "none";
    setTimeout(function () {
      rootElement.style.animation = "";
    }, 1);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [useLocation().pathname]);

  return props.children || null;
};

export default ScrollToTop;
