import React from 'react';
import WithClass from '../../hoc/WithClass';
import styles from './Section.module.scss';

const Section = (props) => {

    let classes = props.classes;
    if (Array.isArray(props.classes)) classes = props.classes.join(' ');

    return (
        <WithClass classes={ [styles.section, classes].join(' ') }>
            { props.children }
        </WithClass>
    );

}

export default Section;